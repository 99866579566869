import React, { useEffect } from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import $ from "jquery";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const Unity = ({ pageContext: { slug } }) => {
  useEffect(() => {
    $(".glitchHoverEffect").hover(function () {
      $(".glitch").toggleClass("effectOn");
    });
  });
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "unity",
        ogImage: require("../../assets/img/portfolio/unity_top_back.png"),
      }}
      seo={{
        title: "Unity Centre",
        headerTitle: "unity",
        href: slug,
        lang: "pl",
        featuredImage: require("../../assets/img/portfolio/unity_featured.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/unity-centre/",
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/rspwn/",
      // }}
    >
      <PortfolioHeader name="unity" height="300" />
      <section className="container-fluid unity_section_2" id="info">
        <div className="row">
          <div className="col-lg-6">
            <div className="inner">
              <h1>Unity Centre</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <p>
                Firma GDK Group zgłosiła się do nas z ambitnym zadaniem
                stworzenia projektu nowej strony internetowej Unity Centre.
                Poprzednia strona nie realizowała celów biznesowych klienta oraz
                umniejszała skali i rozmachowi projektu. Podeszliśmy do tematu
                na nowo. Wraz ze zmianą części akcjonariatu odczarowaliśmy
                krakowskiego "Szkieletora" przemieniając go w najnowocześniejsze
                centrum biznesowe w Krakowie - Unity Centre.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_3">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <h3>Serwis internetowy</h3>
              <p>
                Strona została stworzona w oparciu o technologię GatsbyJS, a nie
                w standardowych technologiach jak HTML, CSS, czy JavaScript.
                Jedną z kluczowych różnic pomiędzy tymi technologiami jest
                szybkość działania strony internetowej, którą można odczuć już w
                ciągu kilku pierwszych sekund jej użytkowania. Sposób
                doświadczania i obcowania z projektem strony internetowej ma
                sprawiać wrażenie lekkości, delikatności oraz intuicyjności, a
                wszystko ma odbywać się pośród nietuzinkowej estetyki.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/unity-website.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_4">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/unity_img_1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <p>
                Opracowaliśmy od nowa pełną architekturę treści oraz informacji,
                prezentując wszystkie atuty centrum biznesowego. Dodatkowo
                skupiliśmy się na pokazaniu stylu Art Déco, który towarzyszy
                całej inwestycji.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_5">
        <div className="row">
          <div className="col-md-7 offset-md-2">
            <img
              src={require("../../assets/img/portfolio/unity_img_2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_6">
        <div className="row">
          <div className="col-md-3 offset-md-5">
            <img
              src={require("../../assets/img/portfolio/unity_img_4.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-4">
            <img
              src={require("../../assets/img/portfolio/unity_img_3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_7">
        <div className="row">
          <div className="col-md-3 offset-md-1">
            <img
              src={require("../../assets/img/portfolio/unity_phone.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5">
            <div className="inner">
              <p>
                "Wieżowiec musi być wysoki w każdym calu.
                <br />
                Obecna musi być w nim siła i moc wysokości. W każdym swoim{" "}
                <br /> calu powinien być dumny i podrywać się do lotu, od czubka
                po <br /> fundament musi być całością, bez jednej choćby linii
                sprzeciwu."
              </p>
              <p className="txt_01">
                Louis H. Sullivan, amerykański architekt, <br />
                twórca pierwszych wysokościowców.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_8">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
      </section>

      <section className="container-fluid unity_section_9">
        <img
          src={require("../../assets/img/portfolio/unity_img_full.png")}
          alt=""
          className="img-fluid"
        />
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Unity;
